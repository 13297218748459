<template>
  <div>
    <button id="newMsg" @click="showModal">
      <i class="fas fa-comment"></i> New Message
    </button>
      <button id="newMsgMobile" @click="showModal">
      <i class="fas fa-comment"></i> NEW
    </button>
    <b-modal id="newMsgModal" ref="my-modal" hide-footer title="Select a Member">
        <b-container
         class="p-0"
        >
        <b-row> <div id="searchField">
          <b-form-input
            type="text"
            class="searchInput"
            v-model="usersSearch"
            placeholder="Search for the name, surname, role or company of your contact"
            debounce="400"
          />
          <i class="fas fa-search" id="searchIcon"></i>
        </div></b-row>
          <b-row id="newMsgRow"  v-for="(user, index) in allUsers"
          :key="index" @click="sendNewMsg(user.user || user)">
            <b-col cols="1" class="m-0 new-msg-user-avatar" v-if="user.customer">
              <img class="userProfilePic" :src="user.customer.profile_picture  ||
                require('../../../assets/default-user-profile-image.png')"
            /></b-col>
            <b-col cols="1" class="m-0 new-msg-user-avatar" v-else-if="user.user">
              <img class="userProfilePic" :src="user.profile_picture ||
                require('../../../assets/default-user-profile-image.png')"
            /></b-col>
            <b-col cols="1" class="m-0 new-msg-user-avatar" v-else>
              <img
                class="userProfilePic"
                src="../../../assets/default-user-profile-image.png"
            /></b-col>
            <b-col cols="10" class="UserDetails" v-if="user.customer">
              <p class="userName">{{ user.first_name }} {{ user.last_name }}</p>
              <p  class="userPosition">
                {{ user.customer.job_title }}
              </p>
            </b-col>
            <b-col  cols="10" class="UserDetails" v-if="user.user">
              <p class="userName">
                {{ user.user.first_name }} {{ user.user.last_name }}
              </p>
              <p class="userPosition">
                {{ user.job_title }}
              </p>
              <p class="userCompany">
                {{ user.company_name }}
              </p>
            </b-col>
            <b-col cols="10" class="UserDetails" v-if="user.customer == null">
              <p class="userName">{{ user.first_name }} {{ user.last_name }}</p>
            </b-col>
          </b-row>
        </b-container>
        <infinite-loading
          spinner="spiral"
          :identifier="userInfiniteId"
          @infinite="infiniteHandler"
        >
        <div slot="no-results"></div>
        <div slot="no-more"></div>
        </infinite-loading>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'NewMessage',

  data() {
    return {
      usersSearch: '',
      allUsers: [],
      options: [],
      company: [],
      userPage: 1,
      usersCount: null,
      usersNextPage: true,
      page: 1,
      userInfiniteId: uuidv4(),
    };
  },
  methods: {
    ...mapActions({
      fetchAllUsersForChat: 'messages/fetchAllUsersForChat',
      createNewConversation: 'messages/createNewConversation',
      searchCustomer: 'user/searchCustomer',
    }),
    async infiniteHandler($state) {
      try {
        const { result } = await this.fetchAllUsersForChat({
          name: '',
          page: this.page,
        });
        if (result.docs.length) {
          this.page += 1;
          this.allUsers.push(...result.docs);
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        $state.complete();
      }
    },
    async sendNewMsg(user) {
      if (user) {
        try {
          const { result } = await this.createNewConversation({
            receiver_id: user.id,
          });
          if (result) {
            this.$router.push({
              name: 'currentMessage',
              params: {
                chatRoomId: result.chat_room_id,
                user,
              },
            });
          }
        } catch (err) {
          console.log(err);
          this.$notify({
            group: 'userAlerts',
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        }
      }
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async getUsers() {
      const { results, next, count } = await this.fetchAllUsersForChat(
        this.userPage,
      );
      this.usersNextPage = !!next;
      this.usersCount = count - 1;
      this.userPage += 1;
      this.users = [
        ...this.users,
        ...results.filter(
          item => !this.allUsers.some(user => user.id === item.id),
        ),
      ].filter(
        user => user.id !== this.userInfo.customer_id && user.user.is_active,
      );
      if (this.allUsers.length < 11 && count > 10) {
        const res = await this.fetchAllUsersForChat(this.userPage);
        const result = res.results;
        const nextPage = res.next;
        const userCount = res.count;
        this.usersNextPage = !!nextPage;
        this.usersCount = userCount - 1;
        this.userPage += 1;
        this.allUsers = [
          ...this.allUsers,
          ...result.filter(
            item => !this.allUsers.some(user => user.id === item.id),
          ),
        ].filter(user => user.id !== this.userInfo.customer_id);
      }
    },
  },

  watch: {
    usersSearch: {
      async handler(name) {
        if (name) {
          this.allUsers = await this.searchCustomer(name);
        } else {
          this.usersNextPage = true;
          // this.userPage = 1;
          await this.getUsers();
        }
      },
    },
  },
};
</script>

<style scoped>
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
#searchField {
  width: 100% !important;
  padding: 4%;
  background-color: #fff;
  position: relative;
}
#searchField > input {
  width: 100%;
}
.row > div{
  padding-left: 15px;
}
#searchField > input::placeholder {
  font-size: 12px;
  color: #aaa;
}
#searchIcon {
  position: absolute;
  left: 90%;
  top: 39% !important;
  color: #aaa;
  cursor: pointer;
}
#newMsg {
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  font-size: 12px;
  background-color: #fff;
  padding: 5px 23px;
}
@media screen and (max-width: 1446px) {
  #newMsg {
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  font-size: 12px;
  background-color: #fff;
  padding: 3px;
  }
}
#newMsgMobile {
  display: none;
  background: white;
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  height: 25px;
  font-size: 12px;
  border-radius: 0 5px 5px 5px;
}
.userProfilePic {
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.UserDetails > p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
#newMsgRow {
  border-bottom: 1px solid #f2f2f2;
  padding-top: 11px;
  padding-bottom: 11px;
  cursor: pointer;
}
#newMsgRow:hover {
  background-color: #F3F3F3;
}
.modal-body {
  padding: 0rem;
}
.userName {
  color: #333 !important;
  position: relative;
  font-weight: 900 !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

.userCompany {
  color: #bd9d5f !important;
  text-transform: capitalize;
  font-size: 12px !important;
}
.userPosition {
  color: #666;
  font-size: 12px !important;
  text-transform: capitalize;
}
#searchInput {
  margin: 0 auto;
  margin-top: 13px;
  display: block;
  width: 93%;
  height: 38px;
  padding: 13px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: unset;
}
.searchIconMsg {
  position: relative;
  width: 31px;
  background: white;
  left: 336px;
  bottom: 50px;
  height: 31px;
  border: 1px solid #cfcfcf;
  padding: 3px 1px 0px 5px;
}
#search-fieldMsg {
  width: 91%;
  margin: 8px 2px 4px 16px;
}
#forSearchIcon {
  position: absolute;
  z-index: 11;
  color: #ced4da;
  top: 11px;
  left: 89%;
}
.header > span {
  color: gray;
  font-size: 18px;
}
#newMsgMultiselect {
  width: 91%;
  margin: 0 auto;
  margin-left: 17px;
}

@media (max-width:768px) {
  #newMsg{
   display: none;
  }
  #newMsgMobile{
    display: block;
  }
  #newMsgModal{
    border-radius: 0 5% 5% 5%;
  }
}
.new-msg-user-avatar  {
  padding-right: 40px !important;
}
.searchInput {
  border: 2px solid #C9C9C9;
  padding-right: 30px;
}

</style>
